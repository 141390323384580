import * as React from 'react';
import { useRouter } from 'next/router';
import { useState } from 'react';

// mui components
import { Grid, Box, CircularProgress } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

// styles
import useStyles from '../styles/style';
import useLoginStyle from '../styles/formStyle';
import { createTheme } from '@mui/material/styles';

// components
import Button from '../components/shared/Button';
import EntryForm from '../components/EntryForm';
import ForgotPassword from './forgot-password';
import CustomTextInput from '../components/CustomInputs/CustomTextInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// redux
import { useSession } from '../hooks';

//validation
import { useFormik } from 'formik';
import * as yup from 'yup';

const Login = () => {
  const router = useRouter();
  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const { signIn } = useSession();
  const { fontWeightBold, fontWeightNormal } = useStyles();
  const classes = useLoginStyle();
  const theme = createTheme();
  const isLoading = false;
  const [isForgot, setIsForgot] = useState(false);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async ({ email, password }) => {
      await signIn({ email, password });
      router.push('/');
    },
  });

  const handleFormChange = () => {
    setIsForgot(!isForgot);
  };

  const loginPageTitleList = [
    {
      title: 'INVENTIVE',
      style: `${classes.companyName} ${fontWeightBold}`,
      variant: 'h5' as Variant,
      margin: 4.5,
    },
    {
      title: 'Welcome back',
      style: `${classes.loginHeader} ${fontWeightBold}`,
      variant: 'h2' as Variant,
      margin: 1,
    },
    {
      title: 'Please enter your details',
      style: `${classes.subHeader} ${fontWeightNormal}`,
      variant: 'h5' as Variant,
      margin: 1,
    },
  ];

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.container}
    >
      {!isForgot ? (
        <>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            className={classes.customFormContainer}
            sx={{
              [theme.breakpoints.down('md')]: {
                width: '50%',
                overflow: 'visible',
              },
            }}
          >
            <Box className={classes.inputWrapper}>
              <EntryForm titleList={loginPageTitleList} />

              <Box my={3}>
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    gap={1}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <CustomTextInput
                      name='email'
                      label='Email'
                      placeholder='Enter your email'
                      value={formik.values.email}
                      handleChange={(e) => formik.setFieldValue('email', e)}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <CustomTextInput
                      name='password'
                      label='Password'
                      placeholder='Enter your password'
                      type={showPassword ? 'text' : 'password'}
                      value={formik.values.password}
                      handleChange={(e) => formik.setFieldValue('password', e)}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      endIcon={
                        showPassword ? <VisibilityOff /> : <Visibility />
                      }
                      endIconOnClick={handleClickShowPassword}
                    />
                  </Box>
                  <Box
                    className={classes.forgotPasswordButtonWrapper}
                    sx={{
                      margin: theme.spacing(1, 0, 4, 0),
                    }}
                  >
                    <Button variant='text' onClick={handleFormChange}>
                      Forgot your password?
                    </Button>
                  </Box>
                  <Box>
                    {isLoading ? (
                      <CircularProgress size={26} />
                    ) : (
                      <Button type='submit' variant='contained'>
                        Sign in
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Box>
          </Grid>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            className={classes.logotypeContainer}
            sx={{
              [theme.breakpoints.down('md')]: {
                width: '50%',
              },
              [theme.breakpoints.down('md')]: {
                display: 'none !important',
              },
            }}
          />
        </>
      ) : (
        <ForgotPassword handleFormChange={handleFormChange} />
      )}
    </Grid>
  );
};

export default Login;

Login.getInitialProps = () => {
  return { useRootLayout: false };
};
