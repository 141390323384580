import React from 'react';
import { Stack, Typography } from '@mui/material';
import useInputStyles from './StateInput/style';

interface Props {
  label: string;
  secondaryLabel?: string;
  optional?: boolean;
  required?: boolean;
}

function CustomLabel(props: Props) {
  const classes = useInputStyles();

  return (
    <Stack spacing={1}>
      <Stack display='flex' flexDirection='row'>
        <Typography className={classes.label}>{props.label}&nbsp;</Typography>
        {props.required && (
          <Typography sx={{ color: 'error.main' }}>*</Typography>
        )}
        {props.optional && (
          <Typography className={classes.optional}>(optional)</Typography>
        )}
      </Stack>

      {props.secondaryLabel && (
        <Typography className={classes.secondaryLabel}>
          {props.secondaryLabel}
        </Typography>
      )}
    </Stack>
  );
}

export default CustomLabel;
