import * as React from 'react';
import { useState } from 'react';

// mui components
import { Grid, Box, CircularProgress } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

// styles
import useStyles from '../styles/style';
import useLoginStyle from '../styles/formStyle';
import { createTheme } from '@mui/material/styles';

// components
import Button from '../components/shared/Button';
import EntryForm from '../components/EntryForm';
import CustomTextInput from '../components/CustomInputs/CustomTextInput';

import { useSession } from '../hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomDialog from '../components/Dialogs';
import DialogHeader from '../components/Dialogs/DialogHeader';
import SuccessDialogContent from '../components/SuccessDialogContent';

interface ForgotPasswordProps {
  handleFormChange: () => void;
}

const ForgotPassword = ({ handleFormChange }: ForgotPasswordProps) => {
  const { startResetPassword } = useSession();
  const { fontWeightBold, fontWeightNormal } = useStyles();
  const classes = useLoginStyle();
  const theme = createTheme();
  const isLoading = false;
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async ({ email }) => {
      await startResetPassword({ email }).then(() => {
        setShowSuccessDialog(true);
        setTimeout(() => {
          handleFormChange();
        }, 1500);
      });
    },
  });

  const forgotPasswordPageTitleList = [
    {
      title: 'INVENTIVE',
      style: `${classes.companyName} ${fontWeightBold}`,
      variant: 'h5' as Variant,
      margin: 4.5,
    },
    {
      title: 'Forgot password?',
      style: `${classes.loginHeader} ${fontWeightBold}`,
      variant: 'h2' as Variant,
      margin: 1,
    },
    {
      title: "No worries, we'll send you reset instructions.",
      style: `${classes.subHeader} ${fontWeightNormal}`,
      variant: 'h5' as Variant,
      margin: 4,
    },
  ];

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.container}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        className={classes.customFormContainer}
        sx={{
          [theme.breakpoints.down('md')]: {
            width: '50%',
            overflow: 'visible',
          },
        }}
      >
        <Box className={classes.inputWrapper}>
          <EntryForm
            titleList={forgotPasswordPageTitleList}
            titleListClassName={classes.titleListWrapper}
          />
          <CustomDialog
            sx={{
              '& .MuiDialog-paper': {
                width: '520px',
                maxWidth: 'unset',
              },
            }}
            open={showSuccessDialog}
            handleClose={() => {
              setShowSuccessDialog(false);
            }}
            header={
              <DialogHeader
                title={'Reset password'}
                onClose={() => setShowSuccessDialog(false)}
              />
            }
            content={
              <SuccessDialogContent regularText='The link to reset your password has been sent to your email.' />
            }
          />
          <form onSubmit={formik.handleSubmit}>
            <CustomTextInput
              name='email'
              placeholder='Enter your email'
              value={formik.values.email}
              handleChange={(e) => {
                formik.setFieldValue('email', e);
              }}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <Box
              className={classes.resetPasswordWrapper}
              sx={{
                margin: theme.spacing(4, 0, 2, 0),
              }}
            >
              {isLoading ? (
                <CircularProgress size={26} />
              ) : (
                <Box className={classes.resetPasswordWrapper}>
                  <Button variant='contained' type='submit'>
                    Reset password
                  </Button>
                </Box>
              )}
            </Box>
          </form>

          <Box className={classes.resetPasswordWrapper}>
            <Button variant='text' onClick={handleFormChange}>
              Back to log in
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;

ForgotPassword.getInitialProps = () => {
  return { useRootLayout: false };
};
