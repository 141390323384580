// @TODO: `makeStyles` is deprecated. Use `styled` from '@mui/material/styles' instead.
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useInputStyles = makeStyles((theme: Theme) => ({
  label: {
    fontWeight: 500,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
  },
  optional: {
    fontWeight: 300,
    fontSize: theme.typography.body1.fontSize,
    fontStyle: 'italic',
  },
  secondaryLabel: {
    fontWeight: 400,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
  placeholder: {
    color: theme.palette.text.secondary,
  },
  dropdown: {
    '& .MuiSelect-select': { display: 'flex', alignItems: 'center' },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
  menuItem: {
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
}));

export default useInputStyles;
