import * as React from 'react';

// mui components
import { Grid, Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

// components
import FormInput from '../shared/Form/FormInput';

interface EntryFormProps {
  titleList?: TitleProps[];
  titleListClassName?: string;
  inputList?: InputProps[];
  inputTitleClassName?: string;
  inputClassName?: string;
}
interface TitleProps {
  title: string;
  style: string;
  variant: Variant;
  margin: number;
}

interface InputProps {
  id: string;
  value: string;
  setValue: (params: string) => void;
  title: string;
  placeholder: string;
  type: string;
}

const EntryForm = ({
  titleList,
  titleListClassName,
  inputList,
  inputTitleClassName,
  inputClassName,
}: EntryFormProps) => {
  return (
    <>
      <Grid
        container
        justifyContent='center'
        flexDirection='column'
        className={titleListClassName}
      >
        {titleList &&
          titleList.map((title: TitleProps, index: number) => (
            <Box mb={title.margin} key={title.title + index}>
              <Typography
                variant={title.variant}
                className={title.style}
                sx={{}}
              >
                {title.title}
              </Typography>
            </Box>
          ))}
      </Grid>
      {inputList &&
        inputList.map((value: InputProps, index: number) => (
          <FormInput
            key={value.id + index}
            className={inputTitleClassName}
            inputClassName={inputClassName}
            id={value.id}
            inputValue={value.value}
            setValue={value.setValue}
            title={value.title}
            placeholder={value.placeholder}
            type={value.type}
          />
        ))}
    </>
  );
};

export default EntryForm;
