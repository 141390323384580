import React, { ReactNode } from 'react';
import {
  Stack,
  Box,
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { CustomTextInputProps } from './inputProps';
import CustomLabel from './CustomLabel';

interface Props extends CustomTextInputProps {
  minWidth?: string;
  endIcon?: ReactNode;
  endIconOnClick?: () => void;
}

function CustomTextInput(props: Props) {
  return (
    <Stack width={'100%'}>
      {!props.hideLabels && props.label && (
        <Box mb={1}>
          <CustomLabel
            required={props.required || false}
            label={props.label}
            secondaryLabel={props.secondaryLabel}
          />
        </Box>
      )}

      <FormControl error={props.error}>
        <OutlinedInput
          id={props.id}
          name={props.name}
          value={props.value}
          inputProps={{ 'aria-label': props.name }}
          onChange={(e) => props.handleChange(e.target.value)}
          onFocus={() => props.handleOnFocus && props.handleOnFocus()}
          placeholder={props.placeholder}
          type={props.type}
          autoComplete={props.autocomplete}
          sx={{
            minWidth: props.minWidth ? props.minWidth : '300px',
            mb: 1,
            '& .MuiOutlinedInput-input': {
              height: 4,
            },
          }}
          startAdornment={
            props.icon && <Box display='flex' alignItems='center' mr={1} />
          }
          endAdornment={
            props.endIcon && (
              <InputAdornment position='end'>
                <IconButton
                  size='small'
                  onClick={props.endIconOnClick}
                  edge='end'
                  data-testid='input-icon-button'
                >
                  {props.endIcon}
                </IconButton>
              </InputAdornment>
            )
          }
          disabled={props.disabled}
        />
        {props.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
}

export default CustomTextInput;
