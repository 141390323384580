// @TODO: `makeStyles` is deprecated. Use `styled` from '@mui/material/styles' instead.
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useLoginStyle = makeStyles((themes: Theme) => ({
  container: {
    height: '100vh',
    width: '100%',
  },
  customFormContainer: {
    backgroundColor: themes.palette.background.paper,
    width: '50%',
    height: '100%',
    overflow: 'auto',
  },
  logotypeContainer: {
    backgroundColor: themes.palette.background.inverted,
    width: '50%',
    height: '100%',
  },
  loginForm: {
    maxWidth: '326px',
  },
  inputWrapper: {
    width: '100%',
    maxWidth: '326px',
  },
  input: {
    width: '100%',
    border: `0.5px solid ${themes.palette.border.actionable}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    textTransform: 'none',
  },
  inputTitle: {
    fontWeight: 500,
    lineHeight: '15px',
    color: themes.palette.text.primary,
  },
  forgotPasswordButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  companyName: {
    width: '150%',
    fontFamily: themes.typography.fontFamilyComfortaa,
    lineHeight: '16px',
    letterSpacing: '0.09em',
    color: themes.palette.text.primary,
  },
  loginHeader: {
    fontSize: themes.typography.h3.fontSize,
    lineHeight: '28px',
    color: themes.palette.text.primary,
  },
  subHeader: {
    lineHeight: '15px',
    color: themes.palette.text.secondary,
  },
  loginIcon: {
    color: themes.palette.text.primary,
    height: '6.8rem',
    width: '6.1rem',
  },
  resetPasswordWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  titleListWrapper: {
    alignContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}));

export default useLoginStyle;
